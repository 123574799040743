import { graphql, useStaticQuery } from "gatsby"

const useBooks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            fields {
              sourceName
            }
            frontmatter {
              title
              novetat
              autors
              preu
              num
              isbn
              uid
              tags
              imatge {
                sharp: childImageSharp {
                  id
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                small: childImageSharp {
                  id
                  fluid(maxWidth: 128, maxHeight: 128, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              edicio
              colleccio
              descripcio
            }
          }
        }
      }
    }
  `)

  return data.allMarkdownRemark.edges
    .filter(({ node }) => node.fields.sourceName === "cataleg")
    .map(({ node }) => ({
      title: node.frontmatter.title,
      novetat: node.frontmatter.novetat,
      autors: node.frontmatter.autors,
      descripcio: node.frontmatter.descripcio,
      edicio: node.frontmatter.edicio,
      imatge: node.frontmatter.imatge,
      num: node.frontmatter.num,
      isbn: node.frontmatter.isbn,
      colleccio: node.frontmatter.colleccio,
      tags: node.frontmatter.tags,
      preu: node.frontmatter.preu,
      uid: node.frontmatter.uid,
    }))
}

export default useBooks
