import React from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import slugify from "slugify"
import Image from "gatsby-image"


const Book = ({ title, autors, imatge, preu }) => {
  const styleProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <animated.div style={styleProps}>
      <Link to={`/cataleg/${slugify(title, { lower: true })}`}>
       <Image
          fluid={imatge.sharp.fluid}
          className="hover:shadow-2xl xl:h-72 lg:h-72 md:h-72"
          alt={`Coberta del llibre | ${title} de ${autors.map(
            e => e
          )} de la Editorial Males Herbes`}
        />
      </Link>

      <div className="py-4">
        <div className="font-title text-myblack italic font-medium text-lg">
          <Link to={`/cataleg/${slugify(title, { lower: true })}`}>
            {title}
          </Link>
        </div>
        <div className="text-myblack text-lg">
          <p className="font-title antialiased ">{autors.join(" i ")}</p>
        </div>
        <div className="text-malesherbes mt-1">
          <p className="font-title">
            {preu
              .toFixed(2)
              .toString()
              .replace(".", ",")}
             €
          </p>
        </div>
      </div>
    </animated.div>
  )
}

export default Book
