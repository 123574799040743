import React, { useRef } from "react"
import AllBooks from "../components/AllBooks"
import AllBooksList from "../components/AllBooksList"
import Cercador from "../components/Cercador"
import Layout from "../components/Layout"
import { useStore } from "../store"
import SEO from "../components/seo"

const Cataleg = () => {
  const { llistat } = useStore(state => state)
  return (
    <Layout>
      <SEO
        title={`Catàleg`}
        lang="ca"
        description="Catàleg de llibres de l'Editorial Males Herbes"
        keywords={[
          "noticies",
          "blog",
          "blog de les males herbes",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="flex flex-wrap">
        <div className="w-full">
          <p className="font-title font-bold uppercase text-gray-900 text-xl border-b border-black">
            Catàleg
          </p>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-1/4 w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 sm:pr-0 sm:pr-0 md:pr-8 lg:pr-8 xl:pr-8">
          <Cercador />
        </div>
        {!llistat && (
          <div class="w-3/4 w-full sm:w-1/2 md:w-3/4 lg:w-3/4 xl:w-3/4 ">
            <div class="w-full h-auto">{<AllBooks />}</div>
          </div>
        )}
        {llistat && (
          <div class="w-3/4 w-full sm:w-1/2 md:w-3/4 lg:w-3/4 xl:w-3/4 mt-12">
            <div class="w-full h-auto">{<AllBooksList />}</div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Cataleg
