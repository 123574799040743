import React from "react"
import { useStore } from "../store"
import useTags from "../hooks/useTags"

const Cercador = ({ autor }) => {
  const { onChange, setTag } = useStore(state => state.actions)
  const { searchName, setLlistat, llistat } = useStore(state => state)
  const tags = useTags()
  return (
    <div class="w-full ">
      <div class="bg-white">
        <input
          className="w-full mt-12 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
          id="bookname"
          type="text"
          value={searchName}
          placeholder={`${
            !autor
              ? `Cercar per títol, autor, col·lecció...`
              : `Cercar autor...`
          }`}
          onChange={onChange}
        />
        <p
          className="mt-2 font-semibold hover:underline cursor-pointer"
          onClick={() => setLlistat(!llistat)}
        >
          {!llistat
            ? `Veure llistat`
            : !autor
            ? `Veure coberta dels llibres`
            : `Veure imatges`}
        </p>

        {/*<div class="hidden md:inline-block">
          {tags.map(t => (
            <p
              class="font-medium hover:underline cursor-pointer"
              onClick={() => setTag(t)}
            >
              <i>{t}</i>
            </p>
          ))}
        </div>*/}
      </div>
    </div>
  )
}

export default Cercador
