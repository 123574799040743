import React, { useState } from "react"
import Book from "./Book"
import { useStore } from "../store"
import useBooks from "../hooks/useBooks"

const AllBooks = ({ initial }) => {
  const books = useBooks()

  const { searchName } = useStore(state => state)
  const [active, setActive] = useState(1)

  const renderBooks = books => {
    const _books = books
      .sort((a, b) => a.uid - b.uid)
      .reverse()
      .filter(book =>
        searchName.length > 0
          ? book.title.toLowerCase().includes(searchName.toLowerCase()) ||
            book.autors[0].toLowerCase().includes(searchName.toLowerCase()) ||
            book.colleccio.toLowerCase().includes(searchName.toLowerCase()) ||
            //book.tags.map(e => e.toLowerCase()).includes(tag.toLowerCase()) ||
            book.tags
              .map(e => e.toLowerCase())
              .includes(searchName.toLowerCase())
          : book
      )

    let pag = active - 1

    return {
      leng: _books.length,
      result: _books.slice(pag * 9, pag * 9 + 9).map(book => {
        return (
          <div
            key={book.title}
            className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 px-2"
          >
            <Book {...book} cataleg={true} />
          </div>
        )
      }),
    }
  }

  return (
    <>
      <div className="flex flex-wrap -mx-2 mt-12 -my-4">
        {renderBooks(books).result.length === 0 ? (
          <p className="text-green-600 text-lg mt-2 font-title h-screen text-center">
            No s'ha trobat cap coincidència...
          </p>
        ) : (
          renderBooks(books).result
        )}
      </div>
      <ul class="flex list-reset rounded w-auto font-title mt-8 cursor-pointer">
        {active > 1 && (
          <li onClick={() => setActive(active - 1)}>
            <p class="block hover:bg-malesherbes text-black border border-malesherbes h-8 w-8 rounded-md px-2 py-1 ml-1">
              &#x2190;
            </p>
          </li>
        )}

        <li>
          <p
            class="bg-malesherbes ml-1 text-white border-malesherbes h-8 w-auto p-1 text-center 
            rounded-md font-medium"
          >
            {active} de {Math.ceil(renderBooks(books).leng / 9)}
          </p>
        </li>

        {active < Math.ceil(renderBooks(books).leng / 9) && (
          <li onClick={e => setActive(active + 1)}>
            <p class="block hover:bg-malesherbes text-black border border-malesherbes h-8 w-8 rounded-md px-2 py-1 ml-1">
              &rarr;
            </p>
          </li>
        )}
      </ul>
    </>
  )
}

export default AllBooks
