import { graphql, useStaticQuery } from "gatsby"

const useBooks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            fields {
              sourceName
            }
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `)

  //const tags
  const tagsArr = data.allMarkdownRemark.edges
    .filter(({ node }) => node.fields.sourceName === "cataleg")
    .map(({ node }) => node.frontmatter.tags)
    .reduce((a, b) => a.concat(b), [])

  return [...new Set(tagsArr)]
}

export default useBooks
